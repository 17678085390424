import { Reference } from "../reference-interface"
import { odInfoCards } from "../../service-info-card-interface/used_by/office-depot-cards"

export const OfficeDepot : Reference = {
  monitorPic: "/images/imac_office_template.png",
  siteName: "Office Depot webshop",
  siteDescription: "Office Depot - Papíráru, nyomtatók és irodaszerek. A nemzetközi Office Depot üzlethálózat webshopját valósítottuk meg és folyamatosan üzemeltetjük.",
  siteUrl: "https://www.officedepot.hu/",
  cards: odInfoCards,
  referencePics: [
    "/images/refs/od/od_1.jpg",
    "/images/refs/od/ref.png",
    "/images/refs/od/od_2.jpg",
  ],
  pageFunctions: [
    "Octopus 8 ERP",
    "Egyedi design",
    "Önálló adminfelület",
    "SEO optimalizálás" ,
    "Ügyfélszolgálat",
    "Terhelésre optimalizált"
  ],
  reversePageFunctions: false,
}
