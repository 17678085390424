import { ServiceInfoCardChildInterface } from "../service-info-card-interface"

const od1 : ServiceInfoCardChildInterface = {
  boldDescription: "",
  bigImg: false,
  description: "A nemzetközi Office Depot üzlethálózat webshopját valósítottuk meg és folyamatosan üzemeltetjük.",
  displayXo: true,
  reverse: false,
  title: "Papíráru, nyomtatók és irodaszerek",
  picture: "/images/refs/od/ref_color.jpg"
}

const od2 : ServiceInfoCardChildInterface = {
  boldDescription: "",
  bigImg: false,
  description: "A működő online megjelenés egy design frissítést kapott, az új webáruház az országos lefedettséget növelte míg a háttér újratervezésével leegyszerűsödtek a folyamatok. Jól működő rendszert alakítottunk ki a különböző típusú adatok gyors kereshetőségére illetve kezelésére.",
  displayXo: true,
  reverse: true,
  title: "Webshop továbbfejlesztés",
  picture: "/images/refs/od/od_2.png"
}

export const odInfoCards = [
  od1,
  od2
]

